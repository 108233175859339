// extracted by mini-css-extract-plugin
export var africarColor = "dd_zf";
export var ammoColor = "dd_yX";
export var babelColor = "dd_zd";
export var cakerColor = "dd_y0";
export var chatticColor = "dd_yY";
export var danColor = "dd_yZ";
export var dumaColor = "dd_y7";
export var dvColor = "dd_zh";
export var ifndColor = "dd_yW";
export var impactColor = "dd_y3";
export var ixColor = "dd_zj";
export var malleniColor = "dd_y2";
export var pmkColor = "dd_zc";
export var refColor = "dd_y8";
export var rewColor = "dd_y9";
export var timebotColor = "dd_y5";
export var tradeaboatColor = "dd_y6";
export var tradervsColor = "dd_y4";
export var variaColor = "dd_y1";
export var wownerColor = "dd_zb";
export var xpcColor = "dd_zg";